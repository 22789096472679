// Form elements
.radio, .checkbox {
  margin-top: 0;
}

// Tables
.table {
  @extend .table-striped;

  @extend .table-hover;
}
