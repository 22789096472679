@import "color-swatches";

// Globals
$base-color: #00b281;
$base-dark-color: #bd2b00;
$base-dark-color-alternative: $wet-asphalt;

$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

$brand-font-size: 1.8em;

$body-bg-primary: $silver;
$body-bg-secondary: $asbestos;

$wrapper-corner-size: 15px;
$sidebar-width: 80px;
$sidebar-bg: $base-dark-color;

$dock-bg: $sidebar-bg;
$dock-active-icon-color: #ffc900;
$dock-link-font-size: 0.8em;

$animation-duration: 0.4s;

$box-shadow-color: rgba(black, 0.04);

// Bootstrap Overrides
$brand-primary: $base-color;
$brand-success: $brand-primary;

$navbar-default-bg: rgba(0, 0, 0, 0);
$navbar-default-brand-color: $base-dark-color;
$navbar-default-brand-hover-color: lighten($base-dark-color, 5);

$breadcrumb-bg: $clouds;
$panel-heading-bg: $clouds;

$dropdown-bg: $sidebar-bg;
$dropdown-border: darken($dropdown-bg, 10) !default;
$dropdown-divider-bg: $dropdown-border;
$dropdown-link-color: $clouds !default;
