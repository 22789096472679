@import "variables";
@import "mixins";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/buttons";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/table-row";
@import "bootstrap_overrides";

html,
body {
  height: 100%;
}

body {
  font-family: $base-font-family;
}

body.login {
  color: $base-dark-color;
  background-color: $body-bg-primary;

  .outer {
    position: absolute;
    display: table;
    width: 100%;
    height: 100%;
  }

  .middle {
    display: table-cell;
    vertical-align: middle;
  }

  .wrapper {
    padding: 20px;
    background-color: $clouds;

    -moz-box-shadow: $box-shadow-color 0 0 0 2px;
    -webkit-box-shadow: $box-shadow-color 0 0 0 2px;
    box-shadow: $box-shadow-color 0 0 0 2px;

    -webkit-border-radius: $wrapper-corner-size;
    border-radius: $wrapper-corner-size;

    border: 1px solid rgba(black, 0.3);
    margin: 0 auto;
    width: 300px;

    .brand {
      margin-top: -15px;

      h1 {
        display: block;
        margin-bottom: 20px;
        font-size: $brand-font-size;
        font-weight: bold;

        .dark {
          color: darken($navbar-default-brand-color, 10);
        }

        .logo-icon {
          width: 75px;
          height: 75px;
          margin: auto;
          padding: 15px;
          border-radius: 40px;
          background: $base-dark-color;

          i[class*='icon-'] {
            margin-left: -5px;
            color: $base-color;
            font-size: 140%;
          }
        }
      }
    }

    .text-center {
      .checkbox {
        text-align: center;
      }

      legend {
        text-align: center;

        input {
          float: none;
        }
      }
    }
  }
}

body.main {
  padding: 10px 20px 0px 20px;
  background-color: $body-bg-primary;
}

.sprite-image {
  background-image: url(../img/sprite.png);
}

form {
  .form-actions {
    background-color: lighten($panel-heading-bg, 3);
    margin: 15px -15px -15px;
    padding: 15px;

    -webkit-border-radius: 0 4px 4px;
    border-radius: 0 4px 4px;

    a.btn:hover {
      color: lighten($base-color, 10);
    }
  }
}

.text-center {
  text-align: center;
}

// Panels
.panel {
  .panel-tools {
    float: right;
    margin-top: -4px;
    margin-right: -8px;

    .btn-group {
      margin-top: -6px;
    }

    .btn-group,
    .label,
    .badge {
      float: right;
      display: inline-block;
    }

    .btn {
      padding: 8px 6px;
      font-weight: 200;
      font-size: 1em;
      color: $base-dark-color;

      &:hover {
        color: $base-color;
      }

      &:active,
      &:focus {
        color: darken($base-color, 10);

        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }

      span.badge {
        font-size: 0.7em;
        padding: 2px 4px;
      }
    }

    .label,
    .badge {
      margin-right: 5px;
      margin-top: 4px;
      padding-bottom: 4px;
      font-weight: 200;
    }
  }
}

// Grids
.grid {
  .filters {
    background-color: lighten($panel-heading-bg, 5);

    .input-group {
      input {
        height: 30px;
      }

      button {
        height: 30px;
        padding: 0 10px;
      }
    }
  }

  table {
    margin-bottom: 0;

    th:first-child,
    td:first-child {
      padding-left: 15px;
    }

    tr.disabled {
      opacity: 0.4;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);

      td {
        text-decoration: line-through;
      }

      td.action {
        text-decoration: none !important;
      }
    }

    td.action {
      width: 15%;
      padding: 5px;

      .btn {
        height: 25px;
        padding: 2px 6px;
      }
    }
  }

  .panel-footer {
    padding: 10px 10px 5px 10px;

    .pagination {
      margin: 0;
    }

    .pull-right {
      padding-top: 5px;
    }
  }
}

.navbar {
  .navbar-brand {
    font-size: $brand-font-size;
    font-weight: bold;

    .dark {
      color: darken($navbar-default-brand-color, 10);
    }
  }

  .nav>li>a {
    padding: 15px 10px;
  }

  .navbar-nav>li>.dropdown-menu {
    margin-top: -5px;

    -webkit-border-radius: 0 $border-radius-base;
    border-radius: 0 $border-radius-base;
  }

  li.dropdown.user img {
    margin: -2px 5px 0 5px;
    width: 20px;
    height: 20px;
    border: 1px solid;
    padding: 1px;
    background-color: rgba(white, 0.6);
  }
}

.wrapper {
  position: relative;
  border: 1px solid rgba(black, 0.2);
  overflow: hidden;

  -webkit-border-radius: $wrapper-corner-size $wrapper-corner-size;
  border-radius: $wrapper-corner-size $wrapper-corner-size;

  -moz-box-shadow: 0 0 0 2px $box-shadow-color;
  -webkit-box-shadow: 0 0 0 2px $box-shadow-color;
  box-shadow: 0 0 0 2px $box-shadow-color;
}

.sidebar {
  background-color: $sidebar-bg;
  width: $sidebar-width;
  z-index: 1;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-right: 1px solid $dropdown-border;

  i.toggle {
    margin-top: 20px;
    font-size: 1.5em;
    display: inline-block;
    color: lighten($base-dark-color, 10);
  }

  ul.dock {
    margin: -15px 0 0 0;
    padding: 0;
    width: $sidebar-width;
    text-align: center;
    color: $clouds;

    li.launcher {
      list-style: none;
      margin-top: 25px;
      cursor: pointer;

      >i {
        display: block;
        font-size: 2.5em;
        margin-bottom: -2px;
      }

      >a {
        height: 36px;
        display: block;
        position: relative;
        padding-top: 36px;
        margin-top: -36px;
        text-decoration: none;
        color: $clouds;
        font-size: $dock-link-font-size;
      }

      ul.dropdown-menu {
        width: 205px;
        border-left-width: 0;
        text-align: left;
        position: absolute;
        margin-left: 85px;
        top: 8px;

        >li {
          line-height: 0;

          >a {
            padding: 5px 20px;
          }
        }

        a,
        li.dropdown-header {
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        li.dropdown-header {
          -webkit-border-radius: 4px;
          border-radius: 4px;

          color: $dock-active-icon-color;
          margin-top: -5px;
          padding: 5px 20px;

          &:before {
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);

            position: absolute;
            margin-top: 4px;
            margin-left: -25px;
            width: 10px;
            height: 10px;
            background-color: $dock-bg;
            content: " ";
            display: inline-block;
          }
        }
      }

      &:hover,
      &.active {

        >i,
        >a {
          -webkit-transition: color $animation-duration ease-in-out;
          -moz-transition: color $animation-duration ease-in-out;
          -ms-transition: color $animation-duration ease-in-out;
          -o-transition: color $animation-duration ease-in-out;
          transition: color $animation-duration ease-in-out;

          color: $dock-active-icon-color;
        }

        &:before {
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);

          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: white;
          position: absolute;
          right: -6px;
          margin-top: 22px;
        }
      }
    }
  }
}

.content {
  min-height: 450px;
  height: 100%;
  padding: 20px;
  margin-left: $sidebar-width;
  background-color: $body-bg;
}

#tools {
  margin-left: 80px;

  .breadcrumb {
    -webkit-border-radius: 0;
    border-radius: 0;

    padding: 15px 20px 15px 20px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 0;
    position: relative;
    z-index: 2;

    .title {
      font-weight: 800;
      color: $base-color;
    }

    a {
      color: darken($silver, 20);
    }
  }

  #toolbar {
    margin-top: -70px;
    margin-right: 10px;
    float: right;
    height: 50px;
    padding: 8px;
    border-bottom: 0;
  }

  .btn-group,
  .label,
  form {
    float: right;
    display: inline-block;
  }

  .btn-group {
    .btn {
      @extend .btn-success;

      span.badge {
        background-color: $base-dark-color;
        padding: 3px 5px;
        font-size: 0.7em;
        font-weight: 200;
      }
    }
  }

  .label {
    margin-right: 5px;
    margin-top: 6px;
    padding: 5px;
    height: 23px;
    font-size: 0.9em;
    font-weight: 200;
  }
}

.beaker {
  @extend .sprite-image;

  position: absolute;
  bottom: 5px;
  background-position: 0 0;
  width: 12px;
  height: 24px;
  background-size: 100px;
  background-repeat: no-repeat;
  margin-left: 33px;
}

.nav-tabs {
  margin-bottom: 15px;
}

.form-group.required .control-label:after {
  content: ' *';
  color: red;
}

.form-horizontal {
  .form-group.required {
    .control-label:before {
      content: '* ';
      color: red;
    }

    .control-label:after {
      content: '';
    }
  }
}

.ui-select-choices.dropdown-menu {
  background-color: #fff;
}

.uib-datepicker-popup.dropdown-menu {
  background-color: #fff;
}

.login-dialog .modal-dialog {
  width: 400px;
}

.users .user {
  margin-top: 20px;
  padding: 0 10px 10px;
  border-radius: $border-radius-base;

  -moz-box-shadow: 0 1px 2px $box-shadow-color;
  -webkit-box-shadow: 0 1px 2px $box-shadow-color;
  box-shadow: 0 1px 2px $box-shadow-color;

  border: 1px solid rgba(black, 0.1);

  .user-name,
  .user-email {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.input-group {
  .uib-timepicker {

    .hours,
    .minutes {
      width: 70px;
    }
  }
}

.permissions-box {
  padding: 6px 12px;

  ul.ivh-treeview {
    margin-bottom: 0;
  }
}

.barra-solicitacao {
  padding: 10px 15px;
  margin-bottom: 15px;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;

  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.urgente {
  background-color: #ffeb00;
  color: #000;
}

.ui-select-container {
  // overflow: hidden; // ATENÇÃO: Se habilitar a lista de opções do uiSelect não aparece.
}

.btn-gray {
  @include button-variant(#000, #ccc, #333);
}

@include table-row-variant('gray', #ccc);

table.solicitacoes {
  table {
    margin-bottom: 0;
  }
}


.pendente-analise span {
  background-color: rgba(234, 255, 41, 0.48);
}

.pendente-analise:hover span {
  background-color: rgba(234, 255, 41, 0.79) !important;
}